<!--
 * @Date: 2023-12-09 17:40:46
 * @LastEditTime: 2023-12-13 23:08:35
 * @Author: wangyongjie
 * @Note:  
-->
<template>
  <div class="dream-theme">
    <div class="dream-theme-wrapper">
      <a-tabs @change="callback" type="line" default-active-key="mark">
        <a-tab-pane key="todo" tab="待办事项">
          <!-- todo代办 -->
          <div class="dream-theme-card">
            <TodoList></TodoList>
          </div>
        </a-tab-pane>
        <a-tab-pane key="notes" tab="文章笔记">
          <!-- notes笔记 -->
          <div class="dream-theme-card">
            <NoteList />
          </div>
        </a-tab-pane>
        <a-tab-pane key="news" tab="全网热搜">
          <!-- news全网热搜 -->
          <div class="dream-theme-card">
            <HotList />
          </div>
        </a-tab-pane>
        <a-tab-pane key="theme" tab="主题记录">
          <!-- 主题计划 -->
          <div class="dream-theme-card">
            <Theme />
          </div>
        </a-tab-pane>
        <a-tab-pane key="mark" tab="书签">
          <!-- 书签 -->
          <div class="dream-theme-card">
            <Mark />
          </div>
        </a-tab-pane>
        <a-tab-pane key="about" tab="关于">
          <!-- 关于 -->
          <div class="dream-theme-card">
            <About />
          </div>
        </a-tab-pane>
        <template slot="tabBarExtraContent">
          <a-row :gutter="[5, 0]">
            <a-col :span="12">
              <a-button size="small">刷新</a-button>
            </a-col>
            <a-col :span="12">
              <a-button size="small">刷新</a-button>
            </a-col>
          </a-row>
        </template>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import TodoList from './TodoList/TodoList'
import NoteList from './NoteList/NoteList'
import HotList from './HotList/HotList'
import Theme from './Theme/Theme'
import Mark from './Mark/Mark'
import About from './About/About'
export default {
  name: 'DreamTheme',
  data() {
    return {
      description: '项目主页',
      info: []
    };
  },
  components: {
    TodoList,
    NoteList,
    HotList,
    Theme,
    Mark,
    About
  },
  created() {
    // TODO
    // 暂时没有循环是因为不知道组件怎么循环
    this.sysSettingInfo();
  },
  mounted() {

  },
  methods: {
    sysSettingInfo() {
      let getSysSetInfo = sessionStorage.getItem('dream_sys_setting_info');
      let storeSysSetInfo = JSON.parse(getSysSetInfo);
      if (storeSysSetInfo) {
        this.info = storeSysSetInfo
      } else {
        this.$http.get('/api/json.json')
          .then(response => {
            if (response.data) {
              this.info = response.data
              sessionStorage.setItem('dream_sys_setting_info', JSON.stringify(response.data));
            }
          })
      }
    },
    callback(key) {
      console.log(key);
    },
    getDate() {

    },
  },
};
</script>

<style scoped lang="less">
.dream-theme {
  width: 100vw;
  height: 100vh;
  // background: #e7f7ff;
  background: #fff;
  padding: 1vh 0 0 0;

  .dream-theme-wrapper {
    width: 98vw;
    height: 98vh;
    margin: 0 auto;
    border: 1px solid #000;
    overflow: hidden;

    /deep/ .ant-tabs {
      .ant-tabs-bar {
        margin: 0;
        border-bottom: 1px solid #000;
      }

      .ant-tabs-tab {
        &:hover {
          color: #000;
        }
      }

      .ant-tabs-tab-active {
        color: #000;
      }

      .ant-tabs-ink-bar {
        background-color: #000000;
      }

      .ant-tabs-content {
        .dream-theme-card {
          height: calc(98vh - 44px);
          background: #fff;
          padding: 10px;
        }
      }
    }
  }
}
</style>

