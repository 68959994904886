<template>
  <div class="doc-content markdown-body">
    <a-spin tip="数据加载中..." :spinning="loading">
      <div class="doc-list">
        愿望清单
        <VueMarkdown :source="planInfo" ref="mdContent" :toc="true" :highlight="true" v-if="false" />
        <!-- <VueMarkdown :source="planInfo" ref="mdContent" /> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import hljs from 'highlight.js';
export default {
  name: 'ThemePlan',
  data() {
    return {
      description: '项目信息',
      loading: true,
      planInfo: '',
      renderedMarkdown: '',
      toc: [],
    }
  },
  components: {
    VueMarkdown
  },

  computed: {

  },
  created() {
    this.getData()
  },
  mounted() {
  },
  watch: {
    loading(newVal, oldVal) {
      // 在这里执行你想要的操作
      this.$nextTick(function () {
        this.mdHighLight()
      })
      console.log('md渲染是否完成,执行code高亮程序，新值为：', newVal, '旧值为：', oldVal);
    },
  },
  methods: {
    getData() {
      this.loading = true;
      let getThemePlanInfo = sessionStorage.getItem('dream_theme_plan_info');
      if (getThemePlanInfo) {
        this.planInfo = getThemePlanInfo;
        this.loading = false;
      } else {
        this.$http.get('https://raw.gitmirror.com/xiaodongxier/my_web_content/dream_data/Theme/ThemeDoc.md', {
          // this.$http.get('https://raw.githubusercontent.com/xiaodongxier/my_web_content/dream_data/Theme/ThemePlan.md', {
          // this.$http.get('/api/md.md', {
          // headers: {
          //   Authorization: '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'
          // },
        })
          .then(response => {
            if (response.data) {
              this.planInfo = response.data
              this.loading = false;
              // sessionStorage.setItem('dream_theme_plan_info', response.data);
            }
          })
      }
    },
    mdHighLight() {
      this.$el.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightBlock(block);
      });
    },
  }
}
</script>
<style lang="less" scoped>
.doc-content {
  border: 1px solid #000;
  border-radius: 5px;

  .doc-list {
    height: 45vh;
    overflow-y: scroll;
    padding: 5px;
  }
}
</style>
