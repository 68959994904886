<!-- https://api.github.com/repos/xiaodongxier/my_web_content/contents/Theme?ref=dream_data -->
<template>
  <div class="log-content">
    <a-spin tip="数据加载中..." :spinning="loading">
      <div class="log-list">
        <a-row type="flex" justify="center">
          <a-col :span="20">
            <a-timeline mode="alternate" pending="持续开发中..." :reverse="reverse">
              <a-timeline-item v-for="(list, index) in logInfo" :key="index" :color="list.setting[3]">
                <a-icon :slot="list.setting[0]" :type="list.setting[1]" style="font-size: 16px;"
                  :spin="list.setting[2]" />
                <h4>{{ list.time }}</h4>
                <VueMarkdown :source="list.content" />
              </a-timeline-item>
            </a-timeline>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>
<script>
import VueMarkdown from "vue-markdown";
export default {
  name: 'ThemeLog',
  data() {
    return {
      description: '主题时间轴',
      reverse: true,
      loading: true,
      logInfo: [],
      noteItemInfo: "# ces"
    }
  },
  components: {
    VueMarkdown
  },
  created() {
    this.getData()
  },
  mounted() {

  },
  methods: {
    getData() {
      this.loading = true;
      let getThemeInfo = sessionStorage.getItem('dream_theme_log_info');
      let themeLogInfo = JSON.parse(getThemeInfo);
      if (themeLogInfo) {
        this.logInfo = themeLogInfo;
        this.loading = false;
      } else {
        this.$http.get('https://raw.gitmirror.com/xiaodongxier/my_web_content/dream_data/Theme/ThemeLog.md', {
          // this.$http.get('https://raw.githubusercontent.com/xiaodongxier/my_web_content/dream_data/Theme/ThemeLog.md?v2', {
          // this.$http.get('https://cdn.jsdelivr.net/gh/xiaodongxier/my_web_content@dream_data/Theme/ThemeLog.md', {
          // this.$http.get('/api/md.md', {
          // headers: {
          //   Authorization: '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'
          // },
        })
          .then(response => {
            if (response.data) {
              const res = this.removeComments(response.data);
              const listContent = res.split("####");
              for (let i = 1; i < listContent.length; i++) {
                const getTitle = listContent[i].split("><");
                const regex = /{%(.*?)%}/;
                const getSet = getTitle[1].match(regex);
                const getContent = getTitle[1].replace(/{%[\s\S]*?%}/g, '');
                this.logInfo.push({
                  time: getTitle[0],
                  setting: JSON.parse(getSet[1]),
                  content: getContent,
                })
                sessionStorage.setItem('dream_theme_log_info', JSON.stringify(this.logInfo));
                this.loading = false;
              }
            }
          })
      }
    },
    // 删除字符串中以<!-- 开始和 -->结尾的所有内容
    removeComments(str) {
      return str.replace(/<!--[\s\S]*?-->/g, '');
    },
    removeBlank(str) {
      return str.replace(/\s+/g, '');
    }
  }
}
</script>
<style lang="less" scoped>
.log-content {
  border: 1px solid #000;

  .log-list {
    // height: calc(90vh / 2 - 6px);
    height: 90vh;
    overflow-y: scroll;
    padding: 15px 0;

    /deep/ .ant-timeline {
      ul ul,
      ul ol,
      ol ul,
      ul ul {
        padding-left: 2em;
      }


      li {
        text-align: justify;

        .ant-timeline-item-tail {}
      }
    }




  }
}
</style>
