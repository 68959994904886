<!--
 * @Date: 2023-11-25 20:26:55
 * @LastEditTime: 2023-12-05 21:36:47
 * @Author: wangyongjie
 * @Note:  
-->
<template>
  <div id="app">
    <HomePage msg="Welcome to Your Vue.js App" />
    <!-- <TestMd></TestMd> -->
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'
// import TestMd from './components/本地测试md文件.vue'

export default {
  name: 'App',
  components: {
    HomePage,
    // TestMd
  }
}
</script>

<style lang="scss">
#app {
  color: #2c3e50;
}

* {
  padding: 0;
  margin: 0;
  font-family: "LXGW WenKai", sans-serif;
}

html,
body {
  overflow: hidden;
}
</style>
