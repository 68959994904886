<template>
  <div class="theme-content">
    <a-row :gutter="[10, 1]">
      <a-col :span="6">
        <AboutMe />
      </a-col>
      <a-col :span="6">
        <AboutDevice />
      </a-col>
      <a-col :span="6">
        <AboutWish />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import AboutMe from './modules/AboutMe'
import AboutDevice from './modules/AboutDevice.vue'
import AboutWish from './modules/AboutWish.vue'
export default {
  name: 'Theme',
  components: {
    AboutMe,
    AboutDevice,
    AboutWish
  },
  data() {
    return {
      description: '程序历史',
      // 正序排/倒序排
      reverse: false

    }
  }
}
</script>
<style lang="less" scoped></style>
