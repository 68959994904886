<template>
  <div class="note-content">
    <a-row :gutter="8">
      <a-col :span="7">
        <div class="note-list note-card">
          <!-- <div class="note-title">
            生活
          </div> -->
          <div clas="note-body">
            <a-table :columns="columns" :data-source="noteList" :pagination="false" :scroll="scroll" :showHeader='false'
              :loading="listLoading" row-key="name">
              <div slot="name" slot-scope="text, record">
                {{ getFileName(record.name) }}
              </div>
              <div slot="action" slot-scope="text, record">
                <a @click="viewNote(record.path, record.name)">阅读</a>
                <a-divider type="vertical" />
                <a :href="viewEdit(record.html_url)" target="_blank">编辑</a>
              </div>
            </a-table>
          </div>
        </div>
      </a-col>
      <a-col :span="17">
        <a-spin tip="文章加载中..." :spinning="viewLoading">
          <div class="list-content note-card">
            <!-- <div class="content-title">
            工作
          </div> -->
            <div class="content-body markdown-body">
              <div v-if="!noteItemInfo" class="note-view-default">阅读区域</div>
              <VueMarkdown :source="noteItemInfo" v-else />
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import hljs from 'highlight.js';
// import marked from 'marked';
export default {
  name: 'NoteList',
  data() {
    return {
      description: '文章列表',
      listLoading: true,
      scroll: { y: "calc(100vh - 30px)" },
      columns: [
        // {
        //   title: '序号',
        //   dataIndex: 'order',
        //   key: 'order',
        //   align: 'center',
        //   width: 70,
        //   customRender: function (t, r, index) {
        //     return parseInt(index) + 1;
        //   }
        // },
        {
          title: '标题',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          dataIndex: 'Action',
          key: 'action',
          width: 130,
          scopedSlots: { customRender: 'action' },
          align: 'center',
        }
      ],
      noteList: [],
      noteItemInfo: '',
      viewLoading: false
    }
  },
  components: {
    VueMarkdown
  },
  created() {
    // sessionStorage.setItem('dream_notes_info', {
    //   noteList: this.noteList,
    //   noteItemInfo: this.noteItemInfo
    // });
    this.getDta()
  },
  watch: {
    viewLoading(newVal, oldVal) {
      // 在这里执行你想要的操作
      this.$nextTick(function () {
        this.mdHighLight()
      })
      console.log('md渲染是否完成,执行code高亮程序，新值为：', newVal, '旧值为：', oldVal);
    },
  },
  mounted() {
  },
  methods: {
    getDta() {
      this.listLoading = true;
      let getNotesListInfo = sessionStorage.getItem('dream_notes_list_info');
      let notesListInfo = JSON.parse(getNotesListInfo);
      if (notesListInfo) {
        this.noteList = notesListInfo;
        this.listLoading = false;
      } else {
        // this.$http.get('/api/note.json', {
        this.$http.get('https://api.github.com/repos/xiaodongxier/my_web_content/contents/NoteList?ref=dream_data', {
          // headers: {
          //   Authorization: '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'
          // },
        })
          .then(response => {
            if (response.data) {
              const fileList = response.data.filter(item => item.name.endsWith('.md'));
              this.noteList = fileList;
              this.listLoading = false;
              sessionStorage.setItem('dream_notes_list_info', JSON.stringify(fileList));
            }
          })
      }
    },
    getFileName(fileName) {
      const fileSplit = fileName.split(".");
      return fileName.split(".")[fileSplit.length - 2]
    },
    getFileType(fileName) {
      const fileSplit = fileName.split(".");
      return fileName.split(".")[fileSplit.length - 1]
    },
    viewNote(notePath, noteName) {
      this.viewLoading = true;
      this.noteItemInfo = ''
      let getNotesContentInfo = sessionStorage.getItem(`dream_notes_content_info_${noteName}`);
      if (getNotesContentInfo) {
        this.noteItemInfo = getNotesContentInfo;
        this.viewLoading = false;
        console.log(noteName, "加载成功,打开的为缓存文件,加载速度快,体验好,0等待")
      } else {
        // let baseUrl = `https://cdn.jsdelivr.net/gh/xiaodongxier/testdata/${notePath}`
        // let baseUrl = `https://cdn.jsdelivr.net/gh/xiaodongxier/my_web_content@dream_data/${notePath}`
        let baseUrl = `https://raw.githubusercontent.com/xiaodongxier/my_web_content/dream_data/${notePath}`
        this.$http.get(baseUrl, {
          // headers: {
          //   Authorization: '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'
          // },
        })
          .then(response => {
            if (response.data) {
              this.noteItemInfo = response.data
              this.viewLoading = false;
              sessionStorage.setItem(`dream_notes_content_info_${noteName}`, response.data);
            }
          })
      }
      this.$nextTick(function () {
        this.mdHighLight()
      })
    },
    viewEdit(edit) {
      // 案例：https://github.com/xiaodongxier/testdata/blob/main/NoteList/issue-84.md
      const editPath = edit.replace("github.com", 'github.dev');
      return editPath
    },
    mdHighLight() {
      this.$el.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightBlock(block);
      });
    },
  },
}
// 
</script>
<style lang="less" scoped>
.note-content {

  .note-card {
    height: (91vh);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;


    &.note-list {
      border: 1px solid #000;
      // background: #2cebc8;
    }

    &.list-content {
      border: 1px solid #000;
      // background: #ccc;

      .content-body {
        overflow: scroll;
        height: 100%;

        .note-view-default {
          height: 100%;
          font-size: 50px;
          color: #0000001f;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }


}
</style>