<template>
  <div class="theme-content">
    <a-row :gutter="[10, 1]">
      <a-col :span="8">
        <ThemePlan />
      </a-col>
      <a-col :span="8">
        <ThemeLog />
      </a-col>
      <a-col :span="8">
        <ThemeDoc />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ThemeLog from './modules/ThemeLog'
import ThemePlan from './modules/ThemePlan.vue'
import ThemeDoc from './modules/ThemeDoc.vue'
export default {
  name: 'Theme',
  components: {
    ThemeLog,
    ThemePlan,
    ThemeDoc
  },
  data() {
    return {
      description: '程序历史',
      // 正序排/倒序排
      reverse: false

    }
  }
}
</script>
<style lang="less" scoped></style>
