<template>
  <div class="hot-content">
    <a-row :gutter="[10, 1]">
      <a-col :span="5">
        <a-spin tip="数据加载中..." :spinning="loading">
          <div class="hot-card baidu-card">
            <h3>百度热搜 <span>{{ baiduData.update_time }}</span></h3>
            <ul>
              <li v-for="item in baiduData.data" :key="item.title">
                <span>{{ item.index }}.</span>
                <a :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="5">
        <a-spin tip="数据加载中..." :spinning="loading">
          <div class="hot-card weibo-card">
            <h3>微博热搜 <span>{{ weiboData.update_time }}</span></h3>
            <ul>
              <li v-for="item in weiboData.data" :key="item.title">
                <span>{{ item.index }}.</span>
                <a :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="5">
        <a-spin tip="数据加载中..." :spinning="loading">
          <div class="hot-card douyin-card">
            <h3>抖音热搜 <span>{{ douyinData.update_time }}</span></h3>
            <ul>
              <li v-for="item in douyinData.data" :key="item.title">
                <span>{{ item.index }}.</span>
                <a :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="9">
        <a-spin tip="数据加载中..." :spinning="loading">
          <div class="hot-card zhihu-card">
            <h3>知乎热搜 <span>{{ zhihuData.update_time }}</span></h3>
            <ul>
              <li v-for="item in zhihuData.data" :key="item.title">
                <span>{{ item.index }}.</span>
                <a :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
                <p v-if="item.desc">
                  {{ item.desc }}
                </p>
              </li>
            </ul>
          </div>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'HotList',
  data() {
    return {
      loading: true,
      description: '热搜',
      baiduData: [],
      weiboData: [],
      douyinData: [],
      zhihuData: []
    }
  },
  created() {
    this.getBdidu()
    this.getWeibo()
    this.getDouyin()
    this.getZhihu()
  },
  mounted() {

  },
  methods: {
    getBdidu() {
      this.$http.get('https://api.vvhan.com/api/hotlist?type=baiduRD')
        .then(response => {
          this.loading = false;
          this.baiduData = response.data;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    getWeibo() {
      this.$http.get('https://api.vvhan.com/api/hotlist?type=wbHot')
        .then(response => {
          this.loading = false;
          this.weiboData = response.data;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    getDouyin() {
      this.$http.get('https://api.vvhan.com/api/hotlist?type=douyinHot')
        .then(response => {
          this.loading = false;
          this.douyinData = response.data;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    getZhihu() {
      this.$http.get('https://api.vvhan.com/api/hotlist?type=zhihuHot')
        .then(response => {
          this.loading = false;
          this.zhihuData = response.data;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    }
  }
}
</script>
<style lang="less" scoped>
.hot-content {
  .hot-card {
    border: 1px solid #000;
    border-radius: 5px;
    height: 91vh;
    overflow: scroll;

    h3 {
      span {
        font-size: 12px;
      }
    }

    li {
      line-height: 30px;
    }

    &.zhihu-card {
      p {
        margin: 0 10px 10px 10px;
        border-radius: 5px;
        padding: 5px;
        background: #ccc;
      }
    }
  }
}
</style>