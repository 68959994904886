<!--
 * @Date: 2023-12-13 23:05:52
 * @LastEditTime: 2023-12-14 13:25:18
 * @Author: wangyongjie
 * @Note:  
-->
<template>
  <div class="mark-content">
    <a-spin tip="数据加载中..." :spinning="loading">
      <a-row :gutter="8">
        <a-col :span="3">
          <div class="mark-nav mark-card">
            <a-anchor :affix="false" :get-current-anchor="getCurrentAnchor">
              <a-anchor-link href="#AI" title="AI" />
              <a-anchor-link href="#Web" title="Web" />
              <a-anchor-link href="#vscode" title="vscode" />
              <a-anchor-link href="#webTools" title="webTools" />
              <a-anchor-link href="#openSource" title="openSource" />
              <a-anchor-link href="#UI" title="UI" />
              <a-anchor-link href="#win" title="win" />
              <a-anchor-link href="#Mac" title="Mac" />
            </a-anchor>
          </div>
        </a-col>
        <a-col :span="21">
          <div class="mark-info mark-card">
            <div class="mark-body">
              <div class="mark-item" v-for="list in info" :key="list.title">
                <a-divider orientation="left" :id="list.description" class="section">
                  {{ list.title }}
                </a-divider>
                <a-row :gutter="[8, 8]">
                  <a-col :span="4" v-for="item in list.children" :key="item.title">
                    <a-card :hoverable="true" :class="{ vpn: item.vpn }">
                      <a :href="item.url" target="_blank">
                        <a-card-meta :title="item.title" :description="item.description">
                          <a-avatar slot="avatar" :src="item.bg" v-if="item.vpn" />
                          <a-avatar slot="avatar" :src="item.avatar" v-else />
                        </a-card-meta>
                      </a>
                    </a-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>

    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'HotList',
  data() {
    return {
      loading: true,
      description: '热搜',
      info: [],
      selectedHref: ''
    }
  },
  created() {
    this.getMarkData()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    getMarkData() {
      let getSysSetInfo = sessionStorage.getItem('dream_mark_info');
      let storeSysSetInfo = JSON.parse(getSysSetInfo);
      if (storeSysSetInfo) {
        this.info = storeSysSetInfo
        this.loading = false
      } else {
        // this.$http.get('/api/mark.json')
        this.$http.get('https://raw.gitmirror.com/xiaodongxier/my_web_content/dream_data/Mark/Mark.json')

          .then(response => {
            if (response.data) {
              this.info = response.data.data
              console.log(response.data)
              this.loading = false
              sessionStorage.setItem('dream_mark_info', JSON.stringify(response.data.data));
            }
          })
      }
    },
    handleScroll() {
      const sections = document.querySelectorAll('.section');
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let selectedHref = '';

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = section.offsetTop + section.offsetHeight;

        if (scrollTop >= sectionTop && scrollTop < sectionBottom) {
          selectedHref = `#${section.id}`;
        }
      });

      this.selectedHref = selectedHref;
    }
  }
}
</script>
<style lang="less" scoped>
.mark-content {

  .mark-card {
    height: (91vh);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #000;

    .mark-body {
      overflow: scroll;
      height: 100%;

      .mark-item {
        margin-bottom: 50px;

        /deep/ .ant-card-meta-description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          height: 42px;
        }
      }
    }

    &.mark-info {
      /deep/ .ant-card {
        overflow: hidden;

        &.vpn {
          &::after {
            content: "vpn";
            font-size: 12px;
            position: absolute;
            top: -20px;
            left: -19px;
            width: 40px;
            height: 40px;
            line-height: 60px;
            text-align: center;
            background: rgba(182, 181, 181, .30);
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
</style>