<template>
  <div class="todo-content markdown-body">
    <a-row :gutter="[8, 4]">
      <a-col :span="6">
        <a-spin tip="Loading..." :spinning="loading">
          <div class="life-todo todo-card">
            <div class="todo-title">
              生活相关
            </div>
            <div class="todo-body">
              <VueMarkdown :source="lifeInfo" />
            </div>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="6">
        <a-spin tip="Loading..." :spinning="loading">
          <div class="work-todo todo-card">
            <div class="todo-title">
              工作相关
            </div>
            <div class="todo-body">
              <VueMarkdown :source="workInfo" />
            </div>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="6">
        <a-spin tip="Loading..." :spinning="loading">
          <div class="study-todo todo-card">
            <div class="todo-title">
              学习相关
            </div>
            <div class="todo-body">
              <VueMarkdown :source="studyInfo" />
            </div>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="6">
        <a-spin tip="Loading..." :spinning="loading">
          <div class="other-todo todo-card">
            <div class="todo-title">
              other相关
            </div>
            <div class="todo-body">
              <VueMarkdown :source="otherInfo" />
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
// import commonMixin from "@/mixins/commonMixin";
export default {
  name: 'TodoList',
  // mixins: [commonMixin],
  data() {
    return {
      description: '代办列表',
      loading: true,
      lifeInfo: '',
      workInfo: '',
      studyInfo: '',
      otherInfo: '',

    }
  },
  components: {
    VueMarkdown
  },
  created() {
    this.getTodoDta()
  },
  mounted() {

  },
  destroyed() {
  },
  methods: {
    // TODO
    // 代码冗余没有循环，后期可以考虑循环优化
    getTodoDta() {
      let getTodoInfo = sessionStorage.getItem('dream_todo_info');
      let storeTodoInfo = JSON.parse(getTodoInfo);
      if (storeTodoInfo) {
        const todoInfo = storeTodoInfo
        const splitData = todoInfo.split('<!-- 分割线 -->');
        this.lifeInfo = splitData[1];
        this.workInfo = splitData[2];
        this.studyInfo = splitData[3];
        this.otherInfo = splitData[4];
        this.loading = false;
      } else {
        // this.$http.get('/api/TodoList/index.md?1')
        // this.$http.get('https://raw.githubusercontent.com/xiaodongxier/my_web_content/dream_data/TodoList/index.md')
        this.$http.get('https://raw.gitmirror.com/xiaodongxier/my_web_content/dream_data/TodoList/index.md')
          .then(response => {
            if (response.data) {
              const todoInfo = response.data
              const splitData = todoInfo.split('<!-- 分割线 -->');
              this.lifeInfo = splitData[1];
              this.workInfo = splitData[2];
              this.studyInfo = splitData[3];
              this.otherInfo = splitData[4];
              this.loading = false;
              sessionStorage.setItem('dream_todo_info', JSON.stringify(response.data));
            }
          })
      }
    }
  },
}
</script>
<style lang="less" scoped>
.todo-content {
  position: relative;

  .todo-card {
    height: (90vh/2 - 0px);
    overflow: scroll;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #000;

    .todo-title {
      text-align: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 80px;
      color: #0c0c0c15;
      z-index: 1;
    }


    &.life-todo {
      // background: #2cebc8;

      &::after {
        content: "生活相关";
      }
    }

    &.work-todo {
      // background: #22aaf8;

      &::after {
        content: "工作相关";
      }
    }

    &.study-todo {
      // background: #d63475;

      &::after {
        content: "学习相关";
      }
    }

    &.other-todo {
      // background: #cebf3e;

      &::after {
        content: "其他相关";
      }
    }
  }
}
</style>