import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "lxgw-wenkai-webfont/style.css";
// GitHub主题
// import "github-markdown-css/css.css";
import "github-markdown-css/github-markdown.css";
// import "github-markdown-css/github-markdown-dark.css"; // 暗
// import "github-markdown-css/github-markdown-light.css"; // 亮

import highlightPlugin from "@highlightjs/vue-plugin";
Vue.use(highlightPlugin);
// 代码高亮主题
// import "highlight.js/styles/a11y-dark.css"; // 暗
import "highlight.js/styles/a11y-light.css"; // 亮
// import "highlight.js/styles/xcode.css"; // 亮
// import "highlight.js/styles/vs.css"; // 亮
// import "highlight.js/styles/stackoverflow-light.css"; // 亮
// import "highlight.js/styles/purebasic.css"; // 亮
// import "highlight.js/styles/nnfx-light.css"; // 亮
// import "highlight.js/styles/monokai-sublime.css"; // 亮

// 行号
// import VueHighlightjsLineNumbers from "highlightjs-line-numbers.js";
// Vue.use(VueHighlightjsLineNumbers);

Vue.prototype.$http = axios;

Vue.use(Antd);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
